const navigation = [
    {
        name: 'about',
        path: {
            en: '/en/about',
            de: '/de/about',
            pl: '/pl/about',
        },
        label: {
            en: 'About the journal',
            de: 'Über die Zeitschrift',
            pl: 'O czasopiśmie',
        },
    },
    {
        name: 'aims-and-scope',
        path: {
            en: '/en/aims-and-scope',
            de: '/de/aims-and-scope',
            pl: '/pl/aims-and-scope',
        },
        label: {
            en: 'Aims and scope',
            de: 'Ziele und Umfang',
            pl: 'Cele i zakres',
        },
    },
    {
        name: 'evaluation',
        path: {
            en: '/en/evaluation',
            de: '/de/evaluation',
            pl: '/pl/evaluation',
        },
        label: {
            en: 'Indexation in databases',
            de: 'Indexierung in Datenbanken',
            pl: 'Indeksacja w bazach danych',
        },
    },
    {
        name: 'editorial-board',
        path: {
            en: '/en/editorial-board',
            de: '/de/editorial-board',
            pl: '/pl/editorial-board',
        },
        label: {
            en: 'Editorial Board',
            de: 'Redaktion',
            pl: 'Zespół Redakcyjny',
        },
    },
    {
        name: 'scientific-board',
        path: {
            en: '/en/scientific-board',
            de: '/de/scientific-board',
            pl: '/pl/scientific-board',
        },
        label: {
            en: 'Scientific Board',
            de: 'Wissenschaftlicher Beirat',
            pl: 'Rada Naukowa',
        },
    },
    {
        name: 'reviewers',
        path: {
            en: '/en/reviewers',
            de: '/de/reviewers',
            pl: '/pl/reviewers',
        },
        label: {
            en: 'Reviewers',
            de: 'Gutachter',
            pl: 'Recenzenci',
        },
    },
    {
        name: 'review-process',
        path: {
            en: '/en/review-process',
            de: '/de/review-process',
            pl: '/pl/review-process',
        },
        label: {
            en: 'Reviewing procedure',
            de: 'Begutachtungsverfahren',
            pl: 'Procedura recenzowania',
        },
    },
    {
        name: 'authors',
        path: {
            en: '/en/authors',
            de: '/de/authors',
            pl: '/pl/authors',
        },
        label: {
            en: 'Authors',
            de: 'Autoren',
            pl: 'Autorzy',
        },
    },
    {
        name: 'authors-info',
        path: {
            en: '/en/authors-info',
            de: '/de/authors-info',
            pl: '/pl/authors-info',
        },
        label: {
            en: 'For the authors',
            de: 'Für Autoren',
            pl: 'Dla Autorów',
        },
    },
    {
        name: 'publishing-policies',
        path: {
            en: '/en/publishing-policies',
            de: '/de/publishing-policies',
            pl: '/pl/publishing-policies',
        },
        label: {
            en: 'Publishing policies',
            de: 'Publikationspolitik',
            pl: 'Polityka wydawnicza',
        },
    },
    {
        name: 'ethics',
        path: {
            en: '/en/ethics',
            de: '/de/ethics',
            pl: '/pl/ethics',
        },
        label: {
            en: 'Ethical principles',
            de: 'Ethische Richtlinien',
            pl: 'Zasady etyczne',
        },
    },
    {
        name: 'keywords',
        path: {
            en: '/en/keywords',
            de: '/de/keywords',
            pl: '/pl/keywords',
        },
        label: {
            en: 'Keywords',
            de: 'Schlüsselwörter',
            pl: 'Słowa kluczowe',
        },
    },
    {
        name: 'archive',
        path: {
            en: '/en/archive',
            de: '/de/archive',
            pl: '/pl/archive',
        },
        label: {
            en: 'All volumes',
            de: 'Alle Bände',
            pl: 'Wszystkie tomy',
        },
    },
    {
        name: 'call-for-papers',
        path: {
            en: '/en/call-for-papers',
            de: '/de/call-for-papers',
            pl: '/pl/call-for-papers',
        },
        label: {
            en: 'Call for papers',
            de: 'Call for papers',
            pl: 'Call for papers',
        },
    },
    {
        name: 'contact',
        path: {
            en: '/en/contact',
            de: '/de/contact',
            pl: '/pl/contact',
        },
        label: {
            en: 'Contact',
            de: 'Kontakt',
            pl: 'Kontakt',
        },
    },
];

export default navigation;
